import React from "react";
import "../assets/css/footer.css";
import footer_logo from "../assets/images/Aurm_Logo.svg";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

/**
 * Shows the Footer of the Website.
 */
function FooterComponent() {
  const scrollToSection = (sectionName) => {
    const scroller_section_tag = document.getElementById(sectionName);
    if (scroller_section_tag) {
      scroller.scrollTo(sectionName, {
        duration: 50,
        smooth: true,
      });
    }
  };
  return (
    <div
      className="footer m-auto d-flex flex-column justify-content-start align-items-center footer_text_color"
      id="footer_section"
    >
      <div className="footer_container d-flex flex-column justify-content-start align-items-center">
        <div className="footer_logo_div">
          <a href="/">
            <img
              src={footer_logo}
              alt="aurm"
              className="footer_logo w-100 h-100"
              loading="lazy"
            />
          </a>
        </div>
        <div className="menu_container d-flex justify-content-center align-items-center  flex_wrap_options">
          <div className="menu_item footer_text_gradient_color">
            <Link
              to="/our_story"
              onClick={() => {
              scrollToSection("our_story_page_div");
            }}
            >
              <div className="menu_text">Our Story</div>
            </Link>
          </div>
          <div className="menu_item footer_text_gradient_color">
            <Link
              to="/"
              onClick={() => {
                scrollToSection("team_section");
                localStorage.setItem("scrollToSection", "team_section");
              }}
            >
              <div className="menu_text">Our Team</div>
            </Link>
          </div>
          <div className="menu_item footer_text_gradient_color">
            <Link to="/blogs" 
            onClick={() => {
              scrollToSection("blogs_page_div");
            }}
            className="link_no_display">
              <div className="menu_text">Blog</div>
            </Link>
          </div>
          <div className="menu_item footer_text_gradient_color">
            <Link
              to="/"
              onClick={() => {
                scrollToSection("contact_section");
                localStorage.setItem("scrollToSection", "contact_section");
              }}
            >
              <div className="menu_text">Contact Us</div>
            </Link>
          </div>
          <div className="menu_item footer_text_gradient_color">
            <Link to="/privacy-policy" 
            onClick={() => {
              scrollToSection("policy_section");
            }}
            className="link_no_display">
              <div className="menu_text">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer_bottom justify-content-center align-items-center">
        <div className="copyright footer_text_gradient_color footer_link">© 2024 Pransu Technologies Pvt. Ltd. All rights reserved. An ISO 9001:2015 and ISO/IEC 27001:2022 Certified Company</div>
        <div className="footer_links d-flex flex-row justify-content-center align-items-center d-none">
        <Link to={"/terms-and-conditions"} className="link_no_display footer_text_gradient_color"> <div className="footer_link">Terms</div></Link>
          <Link to="/privacy-policy" className="link_no_display">
            <div className="footer_link footer_text_gradient_color">Privacy</div>
          </Link>
          <div className="footer_link footer_text_gradient_color">Cookies</div>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;