import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "../assets/css/featured_in.css";
import "../assets/css/featured_in_v.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/blog_post.css";
import blog_post_arrow from "../assets/images/blog_post_arrow.svg";
function FeaturedInVComponent() {
  const blogsJsonData = {
    "partners":[
            {
              "id": 1,
              "image": "https://pub-assets.aurm.in/featuredin_images%2Fvijay_fi_1.png",
              "webp_image": "https://pub-assets.aurm.in/featuredin_images%2Fvijay_fi_1.webp",
              "title": "MyGate founder Vijay Arisetty joins Aurm as founder and CEO",
              "publisherImage": "https://pub-assets.aurm.in/featuredin_images%2FThe_Economic_Times_logo.png",
              "publisherWebpImage": "https://pub-assets.aurm.in/featuredin_images%2FThe_Economic_Times_logo.webp",
              "publisher_page_link": "https://economictimes.indiatimes.com/industry/banking/finance/mygate-founder-vijay-arisetty-joins-aurm-as-founder-and-ceo/articleshow/110896139.cms?from=mdr"
            },
            {
              "id": 2,
              "image": "https://pub-assets.aurm.in/featuredin_images%2Fteam_fi_2.png",
              "webp_image": "https://pub-assets.aurm.in/featuredin_images%2Fteam_fi_2.webp",
              "title": "Serial Entrepreneurs and Armed Forces Veterans launch Aurm",
              "publisherImage": "https://pub-assets.aurm.in/featuredin_images/Inc42_logo.png",
              "publisherWebpImage": "https://pub-assets.aurm.in/featuredin_images/Inc42_logo.webp",
              "publisher_page_link": "https://inc42.com/buzz/flathead-founder-launches-aurm-to-provide-hi-tech-locker-services-to-affluent-indians/"
            },
            {
              "id": 3,
              "image": "https://pub-assets.aurm.in/featuredin_images%2FCollage-Maker-fi_3.png",
              "webp_image": "https://pub-assets.aurm.in/featuredin_images%2FCollage-Maker-fi_3.webp",
              "title": "Aurm Partners with TATA AIG to Offer INR 25 Lakh Insurance for Safe Vaults",
              "publisherImage": "https://pub-assets.aurm.in/featuredin_images%2FThe_Economic_Times_logo.png",
              "publisherWebpImage": "https://pub-assets.aurm.in/featuredin_images%2FThe_Economic_Times_logo.webp",
              "publisher_page_link": "https://economictimes.indiatimes.com/industry/banking/finance/insure/tata-aig-general-insurance-partners-aurm-for-its-vault-services/articleshow/110118817.cms"
            },
            {
              "id": 4,
              "image": "https://pub-assets.aurm.in/featuredin_images%2FBank-locker-Reu_fi_4.png",
              "webp_image": "https://pub-assets.aurm.in/featuredin_images%2FBank-locker-Reu_fi_4.webp",
              "title": "Asset Protection Firm Aurm to Democratize Locker Facilities in India",
              "publisherImage": "https://pub-assets.aurm.in/featuredin_images%2FThe_Economic_Times_logo.png",
              "publisherWebpImage": "https://pub-assets.aurm.in/featuredin_images%2FThe_Economic_Times_logo.webp",
              "publisher_page_link": "https://economictimes.indiatimes.com/tech/startups/flatheads-founder-sets-up-bank-locker-security-venture-with-former-mygate-core-team/articleshow/107624758.cms"
            },
            {
              "id": 5,
              "image": "https://pub-assets.aurm.in/featuredin_images%2FCollage-Maker_fi_5.png",
              "webp_image": "https://pub-assets.aurm.in/featuredin_images%2FCollage-Maker_fi_5.webp",
              "title": "India doesn’t have enough bank lockers to cater to the rising demand: Aurm Report",
              "publisherImage": "https://pub-assets.aurm.in/featuredin_images/money_control.png",
              "publisherWebpImage": "	https://pub-assets.aurm.in/featuredin_images%2Fmoney_control.webp",
              "publisher_page_link": "https://www.moneycontrol.com/news/business/personal-finance/india-doesnt-have-enough-bank-lockers-to-cater-to-the-rising-demand-aurm-report-12384831.html"
            },
            {
              "id": 6,
              "image": "https://pub-assets.aurm.in/featuredin_images%2FBank-locker-Reu_fi_6.png",
              "webp_image": "https://pub-assets.aurm.in/featuredin_images%2FBank-locker-Reu_fi_6.webp",
              "title": "Are your valuables in bank lockers really safe?",
              "publisherImage": "https://pub-assets.aurm.in/featuredin_images/financial_express.png",
              "publisherWebpImage": "https://pub-assets.aurm.in/featuredin_images%2Ffinancial_express.webp",
              "publisher_page_link": "https://www.financialexpress.com/money/are-your-valuables-in-bank-lockers-really-safe-3422442/"
            }
          ]
  };

  const getGroupSize = (width) => {
    if (width < 576) {
      return 1;
    } else if (width < 992) {
      return 2;
    } else {
      return 3;
    }
  };

  const FeaturedInCardsComponent = React.memo((props) => {
    const {
            image,
            webp_image,
            title,
            publisherImage,
            publisherWebpImage,
            publisher_page_link
          } = props;
          return (
            <div className="blog_post_card">
              <a href={publisher_page_link}>
                <picture>
                  <source srcSet={webp_image} type="image/webp" />
                  <img className="blogs-image-class w-100" src={image} alt="BlogPost" loading="lazy" />
                </picture>
              </a>
              <a href={publisher_page_link}>
                <div className="blog_post_title text-white text-left mt-3">
                  <span className="higher_res d-none">
                    {title.length > 100 ? title.substring(0, 100) + "..." : title}
                  </span>
                  <span className="lower_res d-none">
                    {title.length > 60 ? title.substring(0, 60) + "..." : title}
                  </span>
                  <span className="mobile_res d-none">
                    {title.length > 89 ? title.substring(0, 89) + "..." : title}
                  </span>
                  <span className="mobile_res_2 d-none">
                    {title.length > 89 ? title.substring(0, 89) + "..." : title}
                  </span>
                  <span className="blog_post_arrow">
                    <img src={blog_post_arrow} alt="Arrow" />
                  </span>
                </div>
              </a>
              <div className="d-flex image_thumbnail_or_name">
                <div className="blog_post_publicher_image">
                  <picture>
                    <source srcSet={publisherWebpImage} type="image/webp" />
                    <img src={publisherImage} alt="BlogPost_Publisher" style={{ width: "100%", height: "45px" }} loading="lazy" />
                  </picture>
                </div>
              </div>
            </div>
          );
  });

  const [groupSize, setGroupSize] = useState(getGroupSize(window.innerWidth));
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setGroupSize(getGroupSize(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const groupedBlogPosts = blogsJsonData.partners.reduce((acc, blogPost, idx) => {
    if (idx % groupSize === 0) acc.push([]);
    acc[acc.length - 1].push(blogPost);
    return acc;
  }, []);

  const totalSlides = groupedBlogPosts.length;
  const handleSlideChange = (index,) => {
    setCurrentSlide(index);
    const carouselControls = document.getElementById("featured_in_carousel_controls");
    if (carouselControls) {
      const next_icon = carouselControls.querySelector(".carousel-control-next");
      const prev_icon = carouselControls.querySelector(".carousel-control-prev");  
    if (next_icon) {
      prev_icon.style.pointerEvents = index === 0 ? "none" : "auto";
      prev_icon.style.opacity = index === 0 ? "0.5" : "1";
    }
    if (next_icon) {
      next_icon.style.pointerEvents = index === totalSlides - 1 ? "none" : "auto";
      next_icon.style.opacity = index === totalSlides - 1 ? "0.5" : "1";
    }
  }
  };
 
  useEffect(() => {
    currentSlide === 0 ? handleSlideChange(0) :
    handleSlideChange(currentSlide);
  }); 

  return (
    <section id="feature_in_section">
      <div className="container" id="featured_in_container">
        <div id="featred_in_header" className="text-center">
          Featured in
        </div>
        <Carousel id="featured_in_carousel_controls" activeIndex={currentSlide}
          interval={null}
          controls={totalSlides > 1}
          onSelect={handleSlideChange}
          touch={0}
        >
          {groupedBlogPosts.map((group, groupIdx) => (
            <Carousel.Item key={groupIdx}>
              <div className="row">
                {group.map(blogPost => (
                  <div key={blogPost.id} className={`col-md-${12 / groupSize} col-sm-6 col-xs-12 blog_post_cards_div`}>
                    <FeaturedInCardsComponent {...blogPost} />
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default FeaturedInVComponent;


